.statePie > div {
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
}
.chart
	> div
	> svg
	> g
	> g:nth-child(2)
	> g:nth-child(1)
	> g
	> g
	> g
	> g
	> g
	> g:nth-child(1)
	> g
	> g
	> g
	> g:nth-child(4)
	> g
	> g {
	stroke-opacity: 1;
	stroke: white;
}
.statePie > div > div {
	flex-grow: 1;
	min-height: 200px;
	align-self: stretch;
}
.statePie > div > div.chart {
	flex-grow: 1;
	min-height: 400px;
}
/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
	.statePie > div {
		flex-direction: column;
	}
}
