td.action:hover {
	background-color: #444;
}
td.action,
th {
	padding: 0px 10px 0px 10px;
}

tr:hover {
	/* hello firefox */
	//box-shadow: 0px 0px 2px 3px rgba(255, 230, 0, 0.8);
}
table {
	margin: 5px;
	border-collapse: collapse;
}
