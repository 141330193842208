@import "~react-grid-layout/css/styles.css";
@import "~primereact/resources/primereact.min.css";
@import "~primeicons/primeicons.css";
// @import "~primereact/resources/themes/fluent-light/theme.css"; //dark theme
@import "~primereact/resources/themes/bootstrap4-dark-blue/theme.css"; //dark theme

@media only screen and (max-width: 600px) {
	:root {
		font-size: 12px;
	}
}
@media only screen and (min-width: 600px) {
	:root {
		font-size: 14px;
	}
}
//Quill Editor images should fit the tile width
.ql-editor img {
	max-height: 100%;
	max-width: 100%;
}

p,
h1,
h2 {
	margin: 0;
}
p,
div,
span {
	font-size: 1rem;
	font-family: Proxima Nova;
}
.tableItem {
	background: rgb(73, 73, 73);
	background: linear-gradient(
		180deg,
		rgba(73, 73, 73, 1) 0%,
		rgba(28, 28, 28, 1) 100%
	);
}
.extraLink:hover {
	background: #444;
}
.animate.shake {
	animation: shake 0.1s 4;
	color: red;
}

.animate {
	animation: none;
}
@keyframes shake {
	33% {
		transform: translateX(5px);
	}

	66% {
		transform: translateX(-5px);
	}

	100% {
		transform: translateX(0px);
	}
}
@font-face {
	font-family: "Proxima Nova Thin";
	src: local("Proxima Nova Thin"),
		url("../fonts/Proxima Nova Thin.otf") format("opentype");
}
@font-face {
	font-family: "Proxima Nova";
	src: local("Proxima Nova"),
		url("../fonts/Proxima Nova.otf") format("opentype");
}
@font-face {
	font-family: "Proxima Nova Bold";
	src: local("Proxima Nova Bold"),
		url("../fonts/Proxima Nova Bold.otf") format("opentype");
}
@font-face {
	font-family: "Proxima Nova Semibold";
	src: local("Proxima Nova Semibold"),
		url("../fonts/Proxima Nova Semibold.otf") format("opentype");
}
html {
	overflow: auto;
}
::-webkit-scrollbar {
	width: 0px; /* remove scrollbar space */
	background: transparent; /* optional: just make scrollbar invisible */
}
/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
	background: transparent; //#FF0000;
}

body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
	//   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	//   sans-serif;
	//font-family: Proxima Nova Semibold;
	//font-size: $font-size;
	//line-height: $line-height;
	//color: $font-color;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	//background-color: $background-color;
	box-sizing: border-box;
	position: relative;
}
.react-resizable-handle::after {
	border-right: 2px solid white !important;
	border-bottom: 2px solid white !important;
}
